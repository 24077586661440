<template>
  <div class="user-center page-main">
    <div v-if="showUpdateAccount" class="update-account">
      <br>
      <nq-form
        ref="updateLogin"
        submit-text="确认修改"
        :values="formValue"
        :form-list="[
          {
            label: '淘宝旺旺号',
            key: 'name',
            type: 'input',
            placeholder: '淘宝旺旺号',
          },
          {
            label: '电话号码',
            key: 'tel',
            type: 'input',
            placeholder: '请输入电话号码',
          },
        ]"
        @submit="registFormSubmit"
      />
    </div>
    <div class="user-info padding-10">
      <div class="user-info-content">
        <img
          :src="
            userCenterInfo.wechatHeadImage ||
              require('../../assets/image/touxiang.png')
          "
          class="touxiang"
        >
        <div class="right-text">
          <div>
            <span>{{ userCenterInfo.memberName }} </span>
            <span class="logout-icon" @click="logout">
              <img
                src="../../assets/image/logout.png"
                style="width: 20px; position: relative; top: 3px"
              >
            </span>
          </div>
          <div>
            入库码：<span style="color: #cb7760">{{
              userCenterInfo.instorageCode
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="padding-10">
      <div class="money-content">
        <div class="money-info">
          <div>
            <div class="tixian" @click="getMoney">钱包 <span>提现</span></div>
            <div style="margin-bottom: 5px">
              ¥ {{ userCenterInfo.accountBalance }}
            </div>
          </div>
          <div @click="toPage('/coupon')">
            <div>优惠券</div>
            <div style="margin-bottom: 5px">
              {{ userCenterInfo.coupons }} 张
            </div>
          </div>
          <div>
            <div>积分</div>
            <div style="margin-bottom: 5px">
              {{ userCenterInfo.payIntegral }}
            </div>
          </div>
        </div>
        <div class="notice-box">
          <img src="../../assets/image/notice.png" alt="">
          <span />
        </div>
      </div>
    </div>
    <div class="panel">
      <div class="process-text">
        <img src="../../assets/image/double-arrow-right.png">
        寄货流程，三步完成
        <img src="../../assets/image/double-arrow-left.png">
      </div>
    </div>
    <div class="panel">
      <div class="address-container" style="margin-bottom: 20px">
        <div style="flex-grow: 1">
          <div class="panel-title">
            <img
              src="../../assets/image/panel-title-icon.png"
            >第一步货物寄送到仓库
          </div>
          <p>1. 您的货物要先寄到我们的仓库，再转运全球</p>
          <p>2. 请您把货物寄到您的专属仓库，地址如下</p>
          <div style="font-size: 14px; color: #666">（请确保完全一致）</div>
        </div>
      </div>
      <div class="tips">
        注：近期如使用顺丰快递发货，请让商家备注一下或者自己在箱子上写上您的入库码！顺丰系统打单可能造成部分面单上看不见入库码！
      </div>
      <div
        class="primary-button"
        style="margin: 20px auto"
        @click="showDetail = !showDetail"
      >
        查看仓库地址
        <div>
          <img
            src="../../assets/image/down.png"
            style="height: 12px; width: 20px"
          >
        </div>
      </div>

      <template v-if="$baseInfo.IS_CF !== 1">
        <div
          class="address-detail animate"
          :style="!showDetail ? 'height:0;' : ''"
        >
          <ul>
            <li>
              <div class="address-title">邮编:</div>
              <div id="yb" class="address-value" style="width: 70%">215011</div>
              <div class="copy-button" @click="copyText('yb')">复制</div>
            </li>
            <li>
              <div class="address-title">收件人:</div>
              <!-- {{ userCenterInfo.instorageCode }} -->
              <div id="name" class="address-value" style="width: 70%">
                曹林{{ userCenterInfo.instorageCode }}
              </div>
              <div class="copy-button" @click="copyText('name')">复制</div>
            </li>
            <li>
              <div class="address-title">收件电话:</div>
              <div id="tel" class="address-value">15051588558</div>
              <div class="copy-button" @click="copyText('tel')">复制</div>
            </li>
            <li>
              <div class="address-title">收件地址:</div>
              <!-- {{userCenterInfo.instorageCode}} -->
              <div id="address" class="address-value">
                江苏省苏州市虎丘区枫桥街道长江路612号{{
                  userCenterInfo.instorageCode
                }}牛气国际仓库
              </div>
              <div class="copy-button" @click="copyText('address')">复制</div>
            </li>
          </ul>
          <div>
            <div
              class="copy-button"
              style="width: 100px; margin: 0px auto"
              @click="copyText('all')"
            >
              一键复制地址
            </div>
          </div>
          <!-- {{ userCenterInfo.instorageCode }} -->
          <div id="all" style="height: 0px; overflow: hidden">
            邮编:215011 收件人:曹林{{
              userCenterInfo.instorageCode
            }}
            收件电话:15051588558
            收件地址:江苏省苏州市虎丘区枫桥街道长江路612号{{
              userCenterInfo.instorageCode
            }}牛气国际仓库
          </div>
          <div class="warning">
            <span style="color: red">{{ userCenterInfo.instorageCode }}</span>
            是您的专属入库码，我们通过这个来判断哪些是您的包裹，请您寄货至仓库时一定要填写正确！
          </div>
        </div>
      </template>
      <!-- 长发信息 -->
      <template v-else>
        <div
          class="address-detail animate"
          :style="!showDetail ? 'height:0;' : ''"
        >
          <ul>
            <li>
              <div class="address-title">邮编:</div>
              <div id="yb" class="address-value" style="width: 70%">215011</div>
              <div class="copy-button" @click="copyText('yb')">复制</div>
            </li>
            <li>
              <div class="address-title">收件人:</div>
              <div id="name" class="address-value" style="width: 70%">
                杨生{{ userCenterInfo.instorageCode }}
              </div>
              <div class="copy-button" @click="copyText('name')">复制</div>
            </li>
            <li>
              <div class="address-title">收件电话:</div>
              <div id="tel" class="address-value">18068006510</div>
              <div class="copy-button" @click="copyText('tel')">复制</div>
            </li>
            <li>
              <div class="address-title">收件地址:</div>
              <div id="address" class="address-value">
                江苏省苏州市虎丘区枫桥街道长江路612号国际集运仓库{{
                  userCenterInfo.instorageCode
                }}
              </div>
              <div class="copy-button" @click="copyText('address')">复制</div>
            </li>
          </ul>
          <div>
            <div
              class="copy-button"
              style="width: 100px; margin: 0px auto"
              @click="copyText('all')"
            >
              一键复制地址
            </div>
          </div>
          <div id="all" style="height: 0px; overflow: hidden">
            邮编:215011 收件人:杨生{{
              userCenterInfo.instorageCode
            }}
            收件电话:18068006510
            收件地址:江苏省苏州市虎丘区枫桥街道长江路612号国际集运仓库{{
              userCenterInfo.instorageCode
            }}
          </div>
          <div class="warning">
            <span style="color: red">{{ userCenterInfo.instorageCode }}</span>
            是您的专属入库码，我们通过这个来判断哪些是您的包裹，请您寄货至仓库时一定要填写正确！
          </div>
        </div>
      </template>
    </div>
    <div class="panel">
      <div class="panel-title">
        <img src="../../assets/image/panel-title-icon.png">第二步提交打包
      </div>
      <p>1.包裹到达仓库后，请把您需要转运的包裹提交打包</p>
      <p>2.填写您的转运地址（目的地），核查提交</p>
      <div style="text-align: center; margin: 20px 0px">
        <div
          class="nq-button"
          style="position: relative"
          @click="toPage('/order-management/0')"
        >
          我的仓库
          <span v-if="userCenterInfo.stocks > 0" class="nums">{{
            userCenterInfo.stocks
          }}</span>
        </div>
      </div>
    </div>
    <div class="panel">
      <div class="panel-title">
        <img src="../../assets/image/panel-title-icon.png">第三步付款出货
      </div>
      <p>1.待仓库打包完成，您可进行付款</p>
      <p>2.付款完毕，等待发货</p>
      <div class="bottomBtn" style="text-align: center; margin: 20px 0px">
        <div
          class="nq-button"
          style="margin-right: 80px; position: relative"
          @click="toPage('/order-management/1')"
        >
          待发运
          <span v-if="userCenterInfo.shippeds > 0" class="nums">{{
            userCenterInfo.shippeds
          }}</span>
        </div>
        <div class="nq-button" @click="toPage('/order-management/2')">
          已付款
        </div>
      </div>

      <div
        style="
          text-align: right;
          font-size: 13px;
          color: #999;
          margin-top: 10px;
        "
      >
        （付款后物流轨迹点此查询）
      </div>
    </div>
    <div v-if="$baseInfo.IS_CF !== 1" class="panel">
      <div class="panel-title" style="margin-bottom: 20px">
        <img src="../../assets/image/panel-title-icon.png">推广返佣
      </div>
      <div class="other-list all-other" style="margin-top: 30px">
        <div class="money">
          <span class="shouyi">￥{{ userCenterInfo.rakeBackAmount }}</span>
          <div>累计收益</div>
        </div>
        <div class="shuxian" />
        <div @click="tuiguang(userCenterInfo)">
          <img
            src="../../assets/image/tuiguang.png"
            alt=""
            style="width: 36%"
          >
          <div>我的推广</div>
        </div>
        <div @click="friends">
          <img src="../../assets/image/haoyou.png" alt="" style="width: 32%">
          <div>邀请好友</div>
        </div>
        <div @click="returnCom">
          <img src="../../assets/image/guize.png" alt="">
          <div>返佣规则</div>
        </div>
      </div>
    </div>
    <div class="panel">
      <div class="panel-title" style="margin-bottom: 20px">
        <img src="../../assets/image/panel-title-icon.png">其他功能
      </div>
      <div class="other-list">
        <div @click="toPage('/address')">
          <img src="../../assets/image/5.jpg" alt="">
          <div>管理地址簿</div>
        </div>
        <div @click="toPage('/warehousing')">
          <img src="../../assets/image/4.jpg" alt="">
          <div>入库记录</div>
        </div>
        <div @click="toPage('/transaction')">
          <img src="../../assets/image/3.jpg" alt="">
          <div>交易记录</div>
        </div>
        <div @click="toPage('/parcel')">
          <img src="../../assets/image/6.png" alt="">
          <div>包裹认领</div>
        </div>
        <div @click="toPage('/account')">
          <img src="../../assets/image/2.jpg" alt="">
          <div>用户安全</div>
        </div>
        <div v-if="$baseInfo.IS_CF !== 1" @click="toPage('/integral')">
          <img src="../../assets/image/1.jpg" alt="">
          <div>积分商城</div>
        </div>
        <div @click="fahuo">
          <img
            src="../../assets/image/guize.png"
            alt=""
            style="width;40px;height:40px"
          >
          <div>承运契约条款</div>
        </div>
        <div @click="toPage('/youhuiquan')">
          <img
            src="../../assets/image/youhuiquan.png"
            alt=""
            style="width;40px;height:40px"
          >
          <div>领取优惠券</div>
        </div>
        <div @click="mianze">
          <img
            src="../../assets/image/mianze.png"
            alt=""
            style="width;40px;height:40px;"
          >
          <div>免责声明</div>
        </div>
      </div>
    </div>
    <nqDialog
      :title="msgTilte"
      :visible="msgVisible"
      @close="msgVisible = false"
    >
      <!-- 弹框 -->
      <div v-if="msgTilte === '推广返佣规则'">
        <div class="fanyongguize">
          <p>
            1、点击邀请好友按钮，将弹出的图片转发给好友，好友通过图片扫描二维码进入公众号会员中心后，即视为您推广的一位好友。好友通过公众号下单邮寄国际快递后，其支付订单金额将以每笔运费*2%的佣金结算。
          </p>
          <p>
            2、订单佣金并不是实时结算，每月的1至5日为订单佣金结算日，结算金额为好友上一个月邮寄国际快递订单总额*2%。
          </p>
          <p>
            3、如您拥有大量客户资源期望合作，请联系客服洽谈。本活动最终解释权归牛气国际快递所有。
          </p>
        </div>
        <div class="btn" @click="guanbi">
          <button class="nq-button">关闭</button>
        </div>
      </div>
      <div v-if="msgTilte === '承运契约条款'">
        <div class="fahuoxuzhi">
          <!-- <h3>承运契约条款：</h3> -->
          <p>1.寄件人在委托转运物品时应使用英文逐项详细、准确、如实地填写；（集运客户需在集运网站内填写正确收件人名，地址，邮编，电话等）</p>
          <p>2.收、寄件人名地址应详细准确，且应尽可能提供电话号码，以便在快件出现问题时能及时联系；收件人地址仅为邮政信箱号码的快件一律不予转运；本公司对转运快件地址错误或不详所造成的转运投递延误不承担责任；</p>
          <p>3.
            快件内不得夹寄现金，危险品等国家法律及相关规定所列的禁寄物品对寄件人违反禁、限寄所规定所造成的一切损失本公司不承担任何责任；</p>
          <p>4.
            根据国际航空运输协会的相关规定，当快件的体积重量大于实际重量时，快件的运费标准将按照其以及重量予以收取。具体计算公式为：长X宽X高/5000=体积重量</p>
          <p>
            5.
            为保证快件顺利通关，请在相应栏目内用英文详细、如实向海关申报快件内件（包括文件资料）的品名、件数、重量、申报价值及原产国等项目，否则将可能导致通关延误；
          </p>
          <p>6，对地址有误、不详或夹寄禁寄物品的快件，被退回时由寄件人承担退件运输费用及其它费用；</p>
          <p>7，如因海关查验，导致时间延误及物品被没收或关税问题，我司不承担责任！</p>
          <p>8，赔偿标准</p>
          <p>（一）：丢件</p>
          <div style="font-size: 14px;">a.确认我司仓库丢失：赔偿全额货值（最高不超2000元（人民币））</div>
          <div style="font-size: 14px;">b.国际运输中整件丢失 ：退运费+申报价值赔偿<span style="color: red;">（最高不超700人民币）</span></div>
          <div style="font-size: 14px;">c.国际运输中部分丢失 ：赔偿货值的<span style="color: red;">一半</span>（最高不超100元（人民币））</div>
          <div style="font-size: 12px;color: #555555;">
            注：
            a,受理条件：库内免费存储限制货物价值2000元以内 b ，c受理条件：
            （1）漏报，瞒报物品以及金 银 珠宝
            玉石，现金有价证券等限制出口物品不在保障范围内；
            （2）丢件赔偿受理时需提供有实际物价值凭证；
            （3）部分丢失需提供签收时外包装图及箱内图；
          </div>
          <div>
            （二）：破损
          </div>
          <div>1.运输到我司途中破损：协助处理卖家赔偿事宜</div>
          <div>2.国际运输中破损不可复原或者不可使用：补偿物品价值<span style="color: red;">一半</span>（最高不超100元（人民币）</div>
          <div style="font-size: 12px;color: #555555;">
            注：（1）陶瓷等易碎品不在补偿范围内 （（2）破损须提供破损物品图片
            (3张)，以及外包装图
          </div>
          <div>（三）：扣件</div>
          <div>
            国内海关扣件 协助提交处理退运申请
            <span style="color: red;">(实际退运与否取决于海关受理情况) </span>
          </div>
          <div>
            国际海关扣件
            协助清关，(提供商业发票及价值证明，)
          </div>
          <div>注：(国际品牌、药品及违禁品，物品在目的国不符合进口要求不在补偿范围内！）</div>
          <div>（四）：退件</div>
          <div>DHL、UPS、Fedex、TNT，邮政渠道等商业快递转运线路以及包税专线线路国际退件会产生退件运费，需发件人支付;</div>
          <div>选择我司服务即认可我司服务条款，不接受任何异议！最终解释权归我司所有！</div>
        </div>
        <!-- <div class="fahuoxuzhi">
          <p>
            国际快递最不想遇到的麻烦事情就是包裹的时效延误、损毁、遗失，但是国际货运中有很多不确定的因素存在，包括货物本身的性质造成海关的进出口清关延迟、气候原因造成的航班延迟、邮件中转过程中由于某些工作人员的粗暴行为都将会导致您邮包的意外情况发生。
          </p>
          <p>
            虽然不可能100%排除这样的事情发生，但是本公司一定竭尽全力将该类事情的发生率降到最低点，同时我们也会勇敢的承担起对该类邮包的赔偿义务。
          </p>
          <p>1.延误</p>
          <p>
            其他由于恶劣天气、地震、海啸，疫情等不可抗因素造成的延误，或者物品本身的属性造成海关滞留的情况下本公司无能为力，但我们会时刻关注您的包裹动态提供给您最新的转运信息
          </p>
          <p>2.损毁</p>
          <p>
            请当地派送部门（邮政局、dhl、ups，dpd等公司）配合开具书面证明，对损毁物品拍照后一并提交我司的售后客服，我们会尽快处理，
          </p>
          <p>
            A.内件全部损毁的情况下最高赔偿限额为退运费+货物申报价值（但最高不超过100USD）
          </p>
          <p>
            B.部分破损货件只针对破损标的物赔偿，赔偿金额：破损货件重量*20元/KG,最多不超过100USD！
          </p>
          <p>3.遗失</p>
          <p>A.对于邮政渠道，遵循中国邮政赔偿规则（退运费+最高赔偿1倍运费）</p>
          <p>
            B.东南亚专线渠道，DHL，UPS,FEDEX,日韩专线等线路，赔偿退运费+货物申报价值（但最高不超过100USD）
          </p>
          <p>
            C.对于英国空运包税，英国海运包裹，美国空运包税，美国海运包税，欧洲其它国家空运包税，欧洲其它国家海运包税，欧洲铁路包税，欧洲卡航包税，各种小包线路等，赔偿标准为：不退运费，仅赔偿20元/KG
            ！
          </p>
        </div> -->
        <div class="btn" @click="guanbi">
          <button class="nq-button">关闭</button>
        </div>
      </div>
      <div v-if="msgTilte === '邀请好友'" class="beijing">
        <img
          src="../../assets/image/beijingtu.png"
          alt=""
          style="width: 100%"
        >
        <div class="imgTitle">
          <img id="imgCopy" v-loading="imgLoading" src="" alt="">
        </div>
      </div>
      <div v-if="msgTilte === '免责声明'">
        <div class="fahuoxuzhi">
          <p>
            服务中可能发生的问题我们无法一一预告，主要总结了以下几点，请务必仔细阅读。当您注册牛气转运网站会员即代表认可并同意
          </p>
          <p>
            一.因付款不及时造成的发货延误，我公司不承担责任。牛气集运平台提供多种运输方式，您最终选择的运输方式即代表您认可该承运商的所有服务属性（主要包括运送时效、清关能力等）
          </p>
          <p>
            二.本公司为中间服务商，非包裹承运商，国际运输前我们负责提供仓库代收物品、免费仓储管理、分拣、合并封装等服务，运输途中一旦发生快件丢失、破损、延误等情况我们将协助您与承运商或承运商代理之间办理查询、索赔等事宜，具体赔偿标准，请参照“发货须知”赔偿条款！
          </p>
          <p>
            三.客户需对目的地进口国的关税起征点进行一定的了解，部分国家的进口关税较重，各个国家的规定不同，税金一般取决于商品的属性（个人生活用品或是商业用途）、重量、数量、品牌、种类、价值、体积、材质、用途等因素（燕窝、烟草、电子产品、药品、肉类等商品有很多国家是禁止进口的，请自行了解进口国的法律法规）。若海关要求收件人协助清关（例如提供详细的商品信息或进口许可证或购物发票等...）请务必配合，否则包裹将退回中国或当地销毁。部分国家的海关需收件人主动联系清关部门，逾期即退
          </p>
          <p>
            四.禁止寄递的物品与限制寄递的物品请参考“禁限寄说明”，请用户自行遵守相关法律法规，如藏匿此类物品发生货物丢失或海关没收等情况请自行承担后果，情节严重者我们将报警处理
          </p>
          <p>
            五.部分运输方式的计费与体积相关，请用户估算运费时将体积因素考虑在内
          </p>
          <p>
            六.“费用估算”页面展示的费用不定期调整，以当下计算结果为准，费用不涵盖本公司所有收费项目（超规格、磁检、偏远派送、进口关税、等情况除外）。包裹被中国海关退运的情况下不产生运费，若从国外退回，当初您支付的国际运费不予退还，ems与e邮宝以外的运输方式将收取国外退回中国的运费及相关税金，您可以选择承担这笔费用拿回包裹或默认销毁
          </p>
          <p>
            七.仓库内的物品（待处理、待发运）免费存储90天，逾期包裹本公司有权收取仓储费（参照公众号超期包裹仓储费收费标准），销毁、丢弃、廉价变卖、转增等方式处理，逾期自动清除不再另行通知。对于已逾期的包裹本公司不再受理用户申诉及任何赔偿事宜
          </p>
          <p>
            八.包裹目的地派送时请查验后再签收，如遇商品缺损请拒收，并要求派送公司开具书面证明且自行拍照取证，若您签收后发现包裹内物品丢失或损坏，本司无法再追究承运商的任何责任（建议贵重物品封装打包前与我司客服进行核对他是否存在以及完好无损）
          </p>
          <p>九.我司签收就视为邮寄成功！不再受理用户申诉及任何赔偿事宜</p>
          <p>
            十.限制寄递的物品如下：（香烟、雪茄、仿牌类商品遭遇海关查没或退运，我司不受理任何查询及赔偿事宜）
            1.各类食品及调味料 2.国际一二线品牌类 3.毛皮制品 4.香烟、雪茄
            5.音像制品 6.各类药品 7.化妆品 8.电子产品 9.情趣用品
            10.三无产品（仿牌类物品情节严重的将追究法律责任），关于药品、液体、粉末、电子产品、保健品、食品类建议您选择“邮政包裹海运”或敏感品专线，若选择e邮宝/ems/邮政包裹air/邮政包裹sal被中国海关安检退运的包裹将扣除10元/件的验关费
          </p>
          <p>
            十一.在库包裹（未处理或已打包）若不需要国际快递您可以选择国内寄送，提交打包申请后系统在线下单选择国内地址发运即可，快递费中已包含本司服务费（入库、仓储、分拣打包、纸箱、人工费等），您也可以选择安排家人或朋友来库自提或自行安排快递公司取件，但需要支付一定的服务费给予我司。1.未打包的包裹您需要支付2元/kg。2.已整合打包的包裹您需要支付4元/kg
          </p>
          <p>
            十二.海关申报方式分2种，1.授权我司酌情，2.严格按照用户（您）提交的数据为准。若选“酌情”，我司制单人员按照工作经验进行调整并申报，但我司不受理您后期申诉控告我方申报不合理导致的包裹被税、被扣、被拒绝进口、被退回中国等纠纷。该项目比较敏感，请结合目的地国家海关的相关政策和包裹内件物品的属性谨慎操作
          </p>
          <p>
            感谢新老客户对我们信任与体谅，见证我们的成长，我们将做的更好，不辜负大家的期望！
          </p>
        </div>
        <div class="btn" @click="guanbi">
          <button class="nq-button">关闭</button>
        </div>
      </div>
    </nqDialog>
  </div>
</template>
<script>
import { htmlLoginAndRegist, accessToken } from '../../utils/apis/apis.js'
export default {
  name: 'Login',
  data() {
    return {
      formValue: {
        instock: '',
        name: '',
        tel: ''
      },
      showUpdateAccount: false,
      msgTilte: '',
      msgVisible: false,
      routerInfo: '',
      userInfo: null,
      showDetail: false,
      userCenterInfo: {},
      pageInfo: {
        pageSize: 10,
        pageNum: 1
      },
      firstCode: '',
      tuiId: '',
      imgLoading: false
    }
  },
  created() {
    const wxCode = this.$route.query.code
    if (wxCode) {
      if (localStorage.userInfo) {
        this.getUserInfo()
      } else {
        this.$reqGet(accessToken.getWeiXinInfo + '?code=' + wxCode).then(
          (res) => {
            if (res.data.code === 200) {
              localStorage.userInfo = JSON.stringify(res.data.data)
              setTimeout(() => {
                this.getUserInfo()
              }, 10)
            }
          }
        )
      }
    } else {
      this.getUserInfo()
    }
  },
  mounted() {
    // ios下返回做下刷新{很重要}
    window.addEventListener('pageshow', function(e) {
      // 通过persisted属性判断是否存在 BF Cache
      if (e.persisted) {
        window.location.reload()
      }
    })
  },
  methods: {
    registFormSubmit(data) {
      this.$reqPut(accessToken.upDateUserInfo, data).then((res) => {
        if (res.data.code === 200) {
          location.reload()
        } else {
          this.$toast.success(res.data.message)
        }
      })
    },
    getUserInfo() {
      this.$reqGet(htmlLoginAndRegist.getMemberInfo).then((res) => {
        if (res.data.code === 200) {
          if (!res?.data?.data?.id) {
            this.$router.push('/weixin-login')
          } else {
            this.userCenterInfo = res.data.data
            this.firstCode = res.data.data.evaIntegral
            if (this.firstCode === '1' || this.firstCode === 1) {
              this.showDetail = !this.showDetail
            }
            localStorage.userInfo = JSON.stringify(res.data.data)
            this.showUpdateAccount =
              res.data.data.deleteFlag === '3' && this.$baseInfo.IS_CF === 1
            this.formValue = res.data.data
          }
        } else if (res.data.code === 510) {
          this.logout()
        } else {
          this.$router.push('/weixin-login')
        }
      })
    },
    toPage(url) {
      this.$router.push(url)
    },
    logout() {
      localStorage.clear()
      this.$router.push('/login')
    },
    // 提现
    getMoney() {},
    // 返佣规则
    returnCom() {
      this.msgTilte = '推广返佣规则'
      this.msgVisible = true
    },
    fahuo() {
      this.msgTilte = '承运契约条款'
      this.msgVisible = true
    },
    mianze() {
      this.msgTilte = '免责声明'
      this.msgVisible = true
    },
    friends() {
      this.msgTilte = '邀请好友'
      this.imgLoading = true
      this.$reqGet(htmlLoginAndRegist.getPermanentPic).then((res) => {
        this.imgLoading = false
        document
          .getElementById('imgCopy')
          .setAttribute('src', 'data:image/png;base64,' + res.data.data)
      })
      this.msgVisible = true
    },
    guanbi() {
      this.msgVisible = false
    },
    // 复制
    copyText(text) {
      var dummy = document.createElement('textarea')
      document.body.appendChild(dummy)
      dummy.value = document.getElementById(text).innerHTML
      dummy.select()
      document.execCommand('copy')
      document.body.removeChild(dummy)
      this.$toast.success('已复制到粘贴板')
    },
    tuiguang(item) {
      // console.log(item);
      this.$router.push('/extension/' + item.id)
    }
  }
}
</script>
<style lang="less" scoped>
@import url("./index.less");
/deep/ .bottomBtn .nq-button {
  padding: 10px 30px;
}
.nums {
  background: red;
  color: #fff;
  display: inline-block;
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  right: -5px;
  font-size: 12px;
  top: -5px;
}
.shouyi {
  font-size: 24px;
  color: red;
}
.fanyongguize {
  line-height: 24px;
  padding: 8px;
  font-size: 14px;
}
/deep/.money {
  line-height: 28px;
  padding-right: 6px;
  width: 25% !important;
}
/deep/ .all-other > div {
  width: 22%;
}
/deep/.shuxian {
  width: 1px !important;
  height: 60px !important;
  margin: 0 10px;
  background: rgb(224, 221, 221);
}
.beijing {
  position: relative;
}
.imgTitle {
  width: 32%;
  position: absolute;
  bottom: 9.5%;
  left: 34%;
}
#imgCopy {
  width: 100%;
  z-index: 999;
  // width: 49%;
}
.update-account {
  position: fixed;
  left: 0;
  z-index: 999;
  top: 0;
  background: #fff;
  width: 100vw;
  height: 100vh;
}
.fahuoxuzhi {
  height: 400px;
}
/deep/.dialog-body {
  margin-bottom: 50px !important;
}
.btn {
  width: calc(100% - 20px) !important;
  position: absolute;
  bottom: 0;
  text-align: center;
  margin: 10px auto;
}
</style>
